@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css';
@import './styles/paletas';
@import './styles/fonts';
@import url('./modal.scss');


*,
*:before,
*:after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
	border: 0;
}

.container__primary {
	max-width: 1344px;
	margin: 0 auto;

	@media (max-width: 1407px) and (min-width: 1216px) {
		max-width: 85%;
	}

	@media (max-width: 1215px) {
		max-width: 90%;
	}

	@media (max-width: 768px) {
		max-width: 100%;
	}
}

#addtocontact div {
	@media (max-width: 768px) {
		max-width: 90%;
	}
}

.header {
	height: 453px;
	background-position: center;
	background-size: cover;

	@media (max-width: 768px) {
		background-position: center;
		height: 260px;
	}

	@media (max-width: 1023px) and (min-width: 769px) {
		background-position: center;
		height: 453px;
	}
}

.header-img {
	width: 100%;
	height: 100%;
}

.two_columns {
	display: grid;
	grid-template-columns: 50% 50%;
	height: 100%;

	@media (width< 1024px) {
		grid-template-columns: 100%;
	}
}

.profile {
	margin: auto auto;
	border-radius: 50%;
	width: 240px;
	height: 240px;
	border: solid var(--tsg-white) 8px;

	@media (max-width: 768px) {
		width: 160px;
		height: 160px;

		bottom: -31%;
	}

	@media (min-width: 769px) and (max-width: 1023px) {
		width: 190px;
		height: 190px;
		bottom: -16%;
	}
}

.header-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100%;

	@media (max-width: 768px) {
		background-position: center;
		height: 88%;
	}

	@media (min-width: 1024px) and (max-width: 1350px) {
		gap: 20px;

		position: absolute;
		right: -96%;
		top: 0;
	}

	@media (min-width: 1350px) {
		gap: 20px;
		position: absolute;
		right: -77%;
		top: 0;
	}

	.header-logo {
		@media (min-width: 760px) and (max-width: 1200px) {
			height: 42px;
		}

		@media (min-width: 1200px) {
			height: 48px;
			width: 600px;
		}
	}
}

.slider-description-srv {
	@media (max-width: 500px) {
		flex-direction: column;
		gap: 5px !important;

		.line {
			transform: rotate(180deg);
			height: 4px !important;
			width: 60px;
			// border-left: 7px solid var(--dilvant-300);
		}
	}
}

#container__seciton {
	column-count: 2;
	gap: 25px;

	@media (width< 1024px) {
		column-count: 1;
		margin-bottom: 0 !important;
	}
}

.is-hover-btn {
	&:hover {
		filter: brightness(0.9);
	}
}

.mobile-block {
	@media (max-width: 1150px) {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

.qr-box {
	border: var(--rg-primary) solid 1px;
	border-radius: 12px;

	@media (max-width: 769px) {
		border: transparent;
		box-shadow: none!important;
	}
}

// Alert CSS

/* The alert message box */
.alert {
	color: #fff;
	background-color: #04aa6d;
	margin-bottom: 15px;
	padding: 20px;
	position: fixed;
	width: 100%;
	bottom: -15px;
	left: 0;
	z-index: 100;
	display: none;
}

//Serive Card
.services-grid {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto;
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding: 20px;
}

.service-card {
	background-color: var(--tsg-primary);
	border-bottom-right-radius: 36px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	padding: 0;

	img {
		height: 190px;
		width: 190px;
		margin: auto;
		padding: 2px 2px;
	}

	p {
		margin: 8px 20px;
		display: block;
		max-width: 144px;
		height: 63.21px;
		font-size: 15px;
	}
}

/* The close button */
.closebtn {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 22px;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
	color: black;
}

/* Icons color */

.contact-link-btn {
	display: flex !important;
	justify-content: center;
	align-items: center;
	justify-content: center;

	background-color: white;
	width: 55px;
	border: 5px solid #174589;
	border-radius: 50%;

	img { 
		object-fit: contain;
	}
}

.content__icon p { 
	color: #174589 !important;
}


///// MODAL 
.modal input {
	width: 100%;
}

.copy-btn {
	cursor: pointer;
}